<template>
    <form class="contact--form">
        <div class="row" v-if="!isNewEnrollment && !enrollment">
            <div class="small-12 medium-9 columns">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.LABEL') }}
                </label>
                <multiselect v-model="enrollment" track-by='id' label="student_name"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.REMOVE')" :max-height="160"
                    :options="enrollments" :allow-empty="true" :option-height="104" @select="chooseEnrollment" />
            </div>
            <div class="small-12 medium-3 columns add-button-area">
                <div class="add-button">
                    <woot-button size="small" variant="link" icon="add" @click="newEnrollmentButtonToggle">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.LABEL') }}
                    </woot-button>
                </div>
            </div>
        </div>

            <div v-if="enrollment || isNewEnrollment" class="medium-12 columns enrollment-title mb -2">
                <div>
                    <label v-if = "isNewEnrollment" class="page-sub-title" style="font-size: medium; font-weight: bold;">
                        {{$t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.TITLE') }}
                    </label>
                        
                    <div v-else class="medium-12 column gap-1">
                        <label  class="page-sub-title" style="font-size: medium; font-weight: bold;">
                            {{`${$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.TITLE')}`}}
                        </label>
                        <div class="medium-12 row gap-1">
                            <label class="page-sub-title" style="font-size: medium; font-weight: bold;">
                                {{`${enrollment.student_name}`}}
                            </label>
                            <fluent-icon v-if="isDefaulterResponsibleWithSchool"
                                v-tooltip.right-start="$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_IN_SCHOOL_ALERT')"
                                icon="info"
                                size="30"
                                class="pb-1 text-red-700"
                            />

                            <fluent-icon v-if="isDefaulterResponsibleWithKedu"
                                v-tooltip.right-start="$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_IN_KEDU_ALERT')"
                                icon="info"
                                size="30"
                                class="pb-1 text-yellow-300"
                            />
                        </div>
                    </div>
                </div>

                <div class="flex items-center gap-1 mt-2">
                    <woot-button v-tooltip="$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_OVERDUE_BILL.TOOLTIP')" v-if="enrollment && !isNewEnrollment" @click.prevent="handleSearchOverdueBill">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.SEARCH_OVERDUE_BILL.LABEL') }}
                    </woot-button>

                    <woot-button v-tooltip="$t('SCHOOL_CONTACT_FORM.FORM.SYNC_WITH_KECUORE.TOOLTIP')" v-if="enrollment && !isNewEnrollment" @click.prevent="syncWithKeducore">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.SYNC_WITH_KECUORE.LABEL') }}
                    </woot-button>
                </div>
            </div>

            <div  v-if="enrollment || isNewEnrollment" class="row">
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.LABEL') }}
                </label>

                <multiselect v-model="selectedSchool" track-by="id" label="school_name"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.REMOVE')" :max-height="160"
                    :options="schoolAccountsOptions" :allow-empty="true" :option-height="104" @select="chooseSchool" />

            </div>

            <label v-if="(enrollment || isNewEnrollment)">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.LABEL') }}
                <input v-model.trim="schoolDocument" type="text" v-mask="'##.###.###/####-##'" disabled
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.PLACEHOLDER')" />
            </label>

            <label v-if="enrollment && selectedSchool && selectedSchool.extension_session_option_enable">
                <input v-model.trim="extensionSessionEnable" type="checkbox" />
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_OPTION_ENABLE.LABEL') }}
            </label>

            <label v-if="enrollment && generateEnrollmentBillsEnable && allowEnrollmentExemption">
                <input v-model.trim="enrollmentExempt" type="checkbox" />
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_EXEMPT.LABEL') }}
            </label>

            <div  v-if="enrollment" :class="{ error: $v.schoolYear.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.LABEL') }}
                </label>
                <multiselect v-model="schoolYear" :disabled="!selectedSchool"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.REMOVE')" :max-height="160"
                    :options="schoolYearOptions" :allow-empty="true"
                    :option-height="104" @input="$v.schoolYear.$touch" @select="chooseSchoolingType" />
                <span v-if="$v.schoolYear.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.ERROR_MESSAGE') }}
                </span>
            </div>

            <div v-if="enrollment" class="row" :class="{ error: $v.schoolingType.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.LABEL') }}
                </label>
                <multiselect v-model="schoolingType" track-by="VALUE" label="LABEL"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.schoolingType.$touch" @select="chooseSchoolingType" />

                <span v-if="$v.schoolingType.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.ERROR_MESSAGE') }}
                </span>
            </div>

            <div v-if="enrollment" :class="{ error: $v.schoolClass.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.LABEL') }}
                </label>

                <multiselect v-model="schoolClass" 
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.REMOVE')" :max-height="160"
                    :options="schoolClassOptions" :allow-empty="true"
                    :option-height="104" @input="$v.schoolClass.$touch" />

                <span v-if="$v.schoolClass.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="enrollment && !!number" :class="{ error: $v.number.$error }" >
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.LABEL') }}
                    <input v-model.trim="number" type="text" disabled
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.PLACEHOLDER')" @input="$v.number.$touch" />
                    <span v-if="$v.number.$error" class="message">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.ERROR_MESSAGE') }}
                    </span>
            </label>

            <label  v-if="enrollment" :class="{ error: $v.studentName.$error }">  
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.LABEL') }}
                <input v-model.trim="studentName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.PLACEHOLDER')" @input="$v.studentName.$touch" />
                <span v-if="$v.studentName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment" :class="{ error: $v.studentDateOfBirth.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.LABEL') }}
                <input v-model.trim="studentDateOfBirth" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.PLACEHOLDER')"
                    @input="$v.studentDateOfBirth.$touch" />
                <span v-if="$v.studentDateOfBirth.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment" :class="{ error: $v.studentFirstDocument.$error}">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_FIRST_DOCUMENT.LABEL') }}
                <input v-model.trim="studentFirstDocument" type="text" :disabled="hasStudentFirstDocument"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.studentFirstDocument.$touch" v-mask="'###.###.###-##'" />   
                
                <span v-if="$v.studentFirstDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label  v-if="enrollment" :class="{ error: $v.responsibleName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.LABEL') }}
                <input v-model.trim="responsibleName" type="text" :disabled="!isNewEnrollment || hasResponsibleName"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.PLACEHOLDER')"
                    @input="$v.responsibleName.$touch" />
                <span v-if="$v.responsibleName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label  v-if="enrollment" :class="{ error: $v.responsibleZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.LABEL') }}
                <input v-model.trim="responsibleZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.responsibleZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.responsibleZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
                <div class="medium-12 field-button">
                    <woot-button variant="clear" @click.prevent="searchResponsibleZipCode" size="small">
                        {{ this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_ZIPCODE.BUTTON_LABEL') }}
                    </woot-button>
                </div>
            </label>

            <div v-if="enrollment" class="row" :class="{ error: $v.responsibleState.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.LABEL') }}
                </label>
                <multiselect v-model="responsibleState" track-by='ID' label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104" />
                <span v-if="$v.responsibleState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="enrollment" :class="{ error: $v.responsibleCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.LABEL') }}
                <input v-model.trim="responsibleCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.PLACEHOLDER')"
                    @input="$v.responsibleCity.$touch" />
                <span v-if="$v.responsibleCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment" :class="{ error: $v.responsibleDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.LABEL') }}
                <input v-model.trim="responsibleDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.PLACEHOLDER')"
                    @input="$v.responsibleDistrict.$touch" />
                <span v-if="$v.responsibleDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment" :class="{ error: $v.responsibleStreet.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.LABEL') }}
                <input v-model.trim="responsibleStreet" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.PLACEHOLDER')"
                    @input="$v.responsibleStreet.$touch" />
                <span v-if="$v.responsibleStreet.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment" :class="{ error: $v.responsibleBuildingNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.LABEL') }}
                <input v-model.trim="responsibleBuildingNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.PLACEHOLDER')"
                    @input="$v.responsibleBuildingNumber.$touch" />
                <span v-if="$v.responsibleBuildingNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment || isNewEnrollment" :class="{ error: $v.responsibleFirstDocument.$error}">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.LABEL') }}
                <input v-model.trim="responsibleFirstDocument" type="text"
                    :disabled="!!enrollment"
                    :class="{ 'overdue-alert': isDefaulterResponsibleWithKedu }"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.responsibleFirstDocument.$touch" v-mask="'###.###.###-##'" />   
                <div v-if="$v.responsibleFirstDocument.$error" class="message -mt-2 mb-1">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </div>
                <div v-else-if="isDefaulterResponsibleWithSchool" class="message text-yellow-300 -mt-2 mb-1">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_IN_SCHOOL_ALERT') }}
                </div>
                <div v-else-if="isDefaulterResponsibleWithKedu" class="message text-yellow-300 -mt-2 mb-1">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_IN_KEDU_ALERT') }}
                </div>
                <div v-if="isNewEnrollment && !enrollment" class="medium-12 field-button">
                    <woot-button variant="clear" @click.prevent="lookFinancialOwner" size="small" :isLoading="searchForDependentsLoading">
                        {{ this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_FOR_DEPENDENTS.BUTTON_LABEL') }}
                    </woot-button>
                </div>
                 
            </label>

            <div v-if="isNewEnrollment && responsibleFirstDocument && dependents && !enrollment"class="row">
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.DEPENDENTS_SELECT.LABEL') }}
                </label>

                <multiselect v-model="enrollment" track-by="id" label="label"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.DEPENDENTS_SELECT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.DEPENDENTS_SELECT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.DEPENDENTS_SELECT.REMOVE')" :max-height="160"
                    :options="dependentOptions" :allow-empty="true" :option-height="104" @select="chooseDependent" />

            </div>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.totalTuition.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_TUITION.LABEL') }}
                <input v-model.trim="totalTuition" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TOTAL_TUITION.PLACEHOLDER')" @input="$v.totalTuition.$touch" 
                    v-mask="moneyMask"/> 
                <span v-if="$v.totalTuition.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_TUITION.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.installmentNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.LABEL') }}
                <input v-model.trim="installmentNumber" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.PLACEHOLDER')"
                    @input="$v.installmentNumber.$touch" min="1"/>
                <span v-if="$v.installmentNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.ERROR_MESSAGE') + installmentLimit + "." }}
                </span>
            </label>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.tuition.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION.LABEL') }}
                <input v-model.trim="tuition" type="text" disabled
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TUITION.PLACEHOLDER')" @input="$v.tuition.$touch" 
                    v-mask="moneyMask"/> 
                <span v-if="$v.tuition.$error" class="message">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.tuitionDueDate.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.LABEL') }}
                <input v-model.trim="tuitionDueDate" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.PLACEHOLDER')"
                    @input="$v.tuitionDueDate.$touch" />
                <span v-if="$v.tuitionDueDate.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div v-if="enrollment && showFinancialFields">
                <input v-model.trim="totalDiscountIsPercentage" type="checkbox" />
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT_IS_PERCENTAGE.LABEL') }}
                </label>
            </div>

            <label v-if="enrollment && !totalDiscountIsPercentage && showFinancialFields" :class="{ error: $v.totalDiscount.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT.LABEL') }}
                <input v-model.trim="totalDiscount" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT.PLACEHOLDER')" @input="$v.totalDiscount.$touch"
                    v-mask="moneyMask">
                <span v-if="$v.totalDiscount.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && totalDiscountIsPercentage && showFinancialFields" :class="{ error: $v.totalDiscountInPercentage.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT_IN_PERCENTAGE.LABEL') }}
                <input v-model.trim="totalDiscountInPercentage" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT_IN_PERCENTAGE.PLACEHOLDER')" @input="$v.totalDiscountInPercentage.$touch"
                    >
                <span v-if="$v.totalDiscountInPercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TOTAL_DISCOUNT_IN_PERCENTAGE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.discount.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.DISCOUNT.LABEL') }}
                <input v-model.trim="discount" type="text" disabled
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.DISCOUNT.PLACEHOLDER')" @input="$v.discount.$touch"
                    v-mask="moneyMask">
            </label>

            <label v-if="enrollment && showFinancialFields">
                <input v-model.trim="punctualityDiscountRuleEqualToDueDate" type="checkbox" />
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE_EQUAL_TO_DUE_DATE.LABEL') }}
            </label>

            <label v-if="enrollment && showFinancialFields && !punctualityDiscountRuleEqualToDueDate" :class="{ error: $v.punctualityDiscountRule.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.LABEL') }}
                <input v-model.trim="punctualityDiscountRule" type="date" :max="tuitionDueDate"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.PLACEHOLDER')"
                    @input="$v.punctualityDiscountRule.$touch" />
                <span v-if="$v.punctualityDiscountRule.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && showFinancialFields" :class="{ error: $v.costCenter.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.LABEL') }}
                <input v-model.trim="costCenter" type="text" disabled class="disabled"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.PLACEHOLDER')" @input="$v.costCenter.$touch" />
                <span v-if="$v.costCenter.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && extensionSessionCheck && showFinancialFields" :class="{ error: $v.extensionSessionEnrollmentTotalValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_TOTAL_VALUE.LABEL') }}
                <input v-model.trim="extensionSessionEnrollmentTotalValue" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_TOTAL_VALUE.PLACEHOLDER')" @input="$v.extensionSessionEnrollmentTotalValue.$touch" 
                    v-mask="moneyMask"/> 
                <span v-if="$v.extensionSessionEnrollmentTotalValue.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_TOTAL_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="enrollment && extensionSessionCheck && showFinancialFields" :class="{ error: $v.extensionSessionEnrollmentValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_VALUE.LABEL') }}
                <input v-model.trim="extensionSessionEnrollmentValue" type="text" disabled
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_VALUE.PLACEHOLDER')" @input="$v.extensionSessionEnrollmentValue.$touch" 
                    v-mask="moneyMask"/> 
                <span v-if="$v.extensionSessionEnrollmentValue.$error" class="message">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ENROLLMENT_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div v-if="enrollment" class="modal-footer">
                <div class="medium-12 columns">
                    <woot-button 
                        :disabled="inProgress || uiFlags.isCreating || uiFlags.isUpdating || uiFlags.isFetching " @click.prevent="handleSubmitEnrollmentForm"
                    >
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.SUBMIT') }}
                    </woot-button>
                    <button class="button clear" @click.prevent="onCancel">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CANCEL') }}
                    </button>
                </div>
            </div>

            <school-form-modal  
                v-if="showDefaulterModal"
                :show.sync="showDefaulterModal"
                :title="defaulterModalTitle"
                :message="$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.MESSAGE')"
                :buttonProps='defaulterModalButtons'
            />
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { isKeduFieldValid } from 'shared/helpers/KeduValidators';
import maskMixin from 'shared/mixins/inputMaskMixin';
import zipCodeMixin from 'shared/mixins/zipCodeMixin';
import SchoolEnrollmentApi from 'dashboard/api/kedu/schoolEnrollments.js';
import SchoolFormModal from './SchoolFormModal.vue';

export default {
    components: {
        SchoolFormModal,
    },
    props: {
        contact: {
            type: Object,
            default: () => ({}),
        },
        inProgress: {
            type: Boolean,
            default: false,
        },
        selectedSchoolLabels: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showDefaulterModal: false,

            number: '',
            schoolClass: '',
            schoolingType: '',
            schoolYear: '',
            studentName: '',
            studentFirstDocument: '',
            studentDateOfBirth: '',
            responsibleName: '',
            responsibleZipCode: '',
            responsibleState: '',
            responsibleCity: '',
            responsibleDistrict: '',
            responsibleStreet: '',
            responsibleBuildingNumber: '',
            responsibleFirstDocument: '',
            totalTuition: '',
            tuition: '',
            tuitionDueDate: '',
            installmentNumber: '',
            totalDiscountIsPercentage: false,
            totalDiscountInPercentage: 0,
            totalDiscount: '',
            discount: '',
            punctualityDiscountRule: '',
            costCenter: '',
            schoolDocument: '',

            extensionSessionEnrollmentTotalValue: '',
            extensionSessionEnrollmentValue: '',
            extensionSessionEnable: false,

            dependents: null,
            enrollment: null,
            selectedSchool: null,
            isNewEnrollment: false,
            searchForDependentsLoading: false,
            overdueBills: null,
            punctualityDiscountRuleEqualToDueDate: false,
            hasStudentFirstDocument: false,
            hasResponsibleName: true,
            enrollmentExempt: false,
            newEnrollmentDependentRefId: null,
        };
    },
    mixins: [alertMixin, maskMixin, zipCodeMixin],
    validations() {
        let financialFields = {
            totalDiscountInPercentage: {},
            totalDiscount: {},
            extensionSessionEnrollmentTotalValue: {},
            extensionSessionEnrollmentValue: {},
            totalTuition: {},
            tuition: {},
            tuitionDueDate: {},
            installmentNumber: {},
            discount: {},
            punctualityDiscountRule: {},
            costCenter: {}
        };

        if (this.showFinancialFields) {
            let totalDiscount = this.totalDiscountIsPercentage
                ? { totalDiscountInPercentage: { required } }
                : { totalDiscount: { required, keduValidator: this.isDiscountNotValid } };

            let extensionSession = this.extensionSessionCheck
                ? {
                    extensionSessionEnrollmentTotalValue: { required },
                    extensionSessionEnrollmentValue: { required }
                }
                : {
                    extensionSessionEnrollmentTotalValue: {},
                    extensionSessionEnrollmentValue: {}
                };

            let punctualityDiscountRule = this.punctualityDiscountRuleEqualToDueDate 
                ? { 
                    punctualityDiscountRule: {}
                } 
                : {
                    punctualityDiscountRule: { required, punctualityDiscountRuleGreaterThanTuitionDueDate: () => !( this.punctualityDiscountRule > this.tuitionDueDate )}
                };

            financialFields = {
                ...financialFields,
                ...totalDiscount,
                ...extensionSession,
                ...punctualityDiscountRule,
                totalTuition: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                tuition: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                tuitionDueDate: { required },
                installmentNumber: { required, keduValidator: () => !this.isInstallmentNotValid },
                discount: { required },
                costCenter: { required }
            };
        }

        let studentFirstDocument = {};
        if (new Date(this.studentDateOfBirth) < new Date('2017-05-11') && this.studentFirstDocument.length === 0) {
            studentFirstDocument = {
                studentFirstDocument: {}
            };
        } else {
            studentFirstDocument = {
                studentFirstDocument: {
                    keduValidator: this.checkDataValidation('firstDocument'),
                    firstDocumentsAreTheSame: () => !this.firstDocumentsAreTheSame
                }
            };
        }

        return {
            ...financialFields,
            ...studentFirstDocument,
            number: {},
            schoolClass: { required },
            schoolingType: { required },
            schoolYear: { required },
            studentName: { required, minLength: minLength(4), keduValidator: this.checkDataValidation('fullname') },
            studentDateOfBirth: { required },
            responsibleName: { required, keduValidator: this.checkDataValidation('fullname') },
            responsibleZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
            responsibleState: { required },
            responsibleCity: { required, keduValidator: this.checkDataValidation('city') },
            responsibleDistrict: { required },
            responsibleStreet: { required, keduValidator: this.checkDataValidation('street') },
            responsibleBuildingNumber: { required },
            responsibleFirstDocument: {
                required,
                keduValidator: this.checkDataValidation('firstDocument'),
                firstDocumentsAreTheSame: () => !this.firstDocumentsAreTheSame
            }
        };
    },
    computed: {
        ...mapGetters({
            enrollments: 'schoolEnrollments/getSchoolEnrollments',
            uiFlags: 'schoolEnrollments/getUIFlags',
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
            currentUserId: 'getCurrentUserID',
            currentUser: 'getCurrentUser',
        }),
        contactId() {
            return this.contact.id;
        },
        currentAccount() {
            return this.getAccount(this.accountId);
        },
        schoolAccountsOptions() {
            return this.currentAccount?.school_accounts || [];
        },
        schoolYearOptions() {
            return (this.selectedSchool?.school_class_rules && Array.from(
                new Set(
                    this.selectedSchool?.school_class_rules.map(rule => rule.school_year)
                )
            )) || [];
        },
        schoolClassRulesPerSchoolingType() {
            return this.selectedSchool?.school_class_rules?.filter(option => option.schooling_type === this.schoolingType?.VALUE )
        },
        schoolClassOptions() {
            return this.schoolClassRulesPerSchoolingType?.filter(schoolClassRule => schoolClassRule.school_year === this.schoolYear).map(schoolClassRule => schoolClassRule.school_class) || [];
        },
        checkDataValidation() {
            return (dataParams) => (value) => isKeduFieldValid(value, dataParams);
        },
        isDiscountNotValid() {
            if (parseFloat(this.totalDiscount) > parseFloat(this.totalTuition)) {
                return true;
            }
            return false;
        },
        isInstallmentNotValid() {
            if (this.installmentNumber > this.installmentLimit) {
                return true;
            }
            return false;
        },
        installmentLimit() {
            if (this.schoolYear === '2024') return 1;
         
            const startMonth = (this.tuitionDueDate && (this.tuitionDueDate.split("-")[1] - 1)) || new Date().getMonth();
            const countToJanuary = 12 - startMonth;
            return countToJanuary < 6 ? countToJanuary : 6
        },
        dependentOptions(){
            const newDependentOption = {
                label: this.$t('SCHOOL_CONTACT_FORM.FORM.NEW_DEPENDENT_OPTION')
            }

            return [...this.dependents, newDependentOption]
        },
        contactLabels(){
            return this.$store.getters['contactLabels/getContactLabels'](this.contact.id);
        },
        isDefaulterResponsibleWithSchool(){
            return !!this.overdueBills?.overdue_school_bills?.length;
        },
        isDefaulterResponsibleWithKedu(){
            return !!this.overdueBills?.overdue_bank_bills?.length;
        },
        defaulterModalTitle(){
            return this.isDefaulterResponsibleWithSchool ? this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.IN_SCHOOL_TITLE') : this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.IN_KEDU_TITLE') 
        },
        defaulterModalButtons(){
            return this.isDefaulterResponsibleWithSchool ? this.defaulterWithSchoolButtons : this.defaulterWithKeduButtons
        },
        defaulterWithSchoolButtons(){
            return [
                {variant: "clear", onClick: this.handleDefaulterSendMessageButton, text: this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.BUTTONS.SEND_DEFAUTER_MESSAGE')},
                {variant: "clear", onClick: this.handleDefaulterCancelButton, text: this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.BUTTONS.CANCEL')}
            ]
        },
        defaulterWithKeduButtons(){
            return [
                {variant: "clear", onClick: this.handleDefaulterSendMessageButton, text: this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.BUTTONS.SEND_DEFAUTER_MESSAGE')},
                {variant: "clear", onClick: this.handleDefaulterContinueButton, text: this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.BUTTONS.CONTINUE')}
            ]
        },
        conversations() {
            return this.$store.getters['contactConversations/getContactConversation'](
                this.contact.id
            );
        },
        lastConversation() {
            return this.conversations.reduce((latest, current) => {
                return current.created_at > latest.created_at ? current : latest;
            })
        },
        firstDocumentsAreTheSame() {
            return this.responsibleFirstDocument === this.studentFirstDocument;
        },
        extensionSessionCheck() {
            return this.extensionSessionEnable && this.selectedSchool?.extension_session_option_enable
        },
        generateEnrollmentBillsEnable() {
            return this.selectedSchool ? this.selectedSchool.generate_enrollment_bills_enable : true;
        },
        allowEnrollmentExemption() {
            return this.selectedSchool ? this.selectedSchool.allow_enrollment_exemption : false;
        },
        showFinancialFields() {
            return this.generateEnrollmentBillsEnable && (!this.allowEnrollmentExemption || !this.enrollmentExempt);
        }
    },
    mounted() {
        this.fetchLabels();
        this.$store.dispatch('contactConversations/get', this.contact.id);
    },
    watch: {
        contactId() {
            this.onCancel();
        },
        totalDiscount(val) {
            const limitValue = (Math.floor(this.moneyMaskToDecimal(this.totalTuition) * 90) / 100).toFixed(2);
            const parsedVal = this.moneyMaskToDecimal(val);

            if ((parsedVal > limitValue) ||
                (this.totalTuition == '')) {
                this.totalDiscount = limitValue;
            }

            this.discount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalDiscount) / this.installmentNumber)}`;
        },

        totalDiscountInPercentage(val) {
            if (val  > 90) {
                this.totalDiscountInPercentage = 90;
            }
            this.totalDiscount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalTuition) * val/100)}`;
            this.discount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalDiscount) / this.installmentNumber)}`;
        },

        totalDiscountIsPercentage(val) {
            this.totalDiscount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalTuition) * this.totalDiscountInPercentage/100)}`;
            this.discount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalDiscount) / this.installmentNumber)}`;
        },
        
        installmentNumber(val) {
            if (parseInt(val) > this.installmentLimit) {
                this.installmentNumber = this.installmentLimit;
            }
            this.tuition = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalTuition) / this.installmentNumber)}`;
            this.discount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalDiscount) / this.installmentNumber)}`;
        },
        totalTuition(val) {
            this.tuition = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(val) / this.installmentNumber)}`;

            if (this.totalDiscountIsPercentage) {
                this.totalDiscount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(val) * this.totalDiscountInPercentage/100)}`;
                this.discount = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(this.totalDiscount) / this.installmentNumber)}`;
            }
        },
        schoolYear(val){
            this.costCenter = `KEDU ONE MATRICULA ${val || ''}`;
        },
        extensionSessionEnrollmentTotalValue(val) {
            this.extensionSessionEnrollmentValue = `${this.roundMoneyToFloor(this.moneyMaskToDecimal(val) / this.installmentNumber)}`;
        },
        studentFirstDocument(val){
            if (!this.$v.studentFirstDocument.$invalid && val !== this.enrollment?.student_first_document) {
                this.searchDependentDocument(val);
            }
        }
    },
    methods: {
        onCancel() {
            this.enrollment = null;
            this.isNewEnrollment = false;
            this.dependents = null;
            this.overdueBills = null;
            this.selectedSchool = null;
            this.searchForDependentsLoading = false;
            this.hasStudentFirstDocument = false;

            this.number = '';
            this.schoolClass = '';
            this.schoolingType = '';
            this.schoolYear = '';
            this.studentName = '';
            this.studentFirstDocument = '',
            this.studentDateOfBirth = '';
            this.responsibleName = '';
            this.responsibleZipCode = '';
            this.responsibleState = '';
            this.responsibleCity = '';
            this.responsibleDistrict = '';
            this.responsibleStreet = '';
            this.responsibleBuildingNumber = '';
            this.responsibleFirstDocument = '';
            this.tuition = '';
            this.totalTuition = '';
            this.tuitionDueDate = '';
            this.installmentNumber = '';
            this.discount = '';
            this.totalDiscountIsPercentage = false;
            this.totalDiscountInPercentage = '';
            this.totalDiscount = '';
            this.punctualityDiscountRule = '';
            this.costCenter = '';
            this.schoolDocument = '';

            this.extensionSessionEnrollmentValue = '';
            this.extensionSessionEnrollmentTotalValue = '';
            this.extensionSessionEnable = false;
            this.enrollmentExempt = false;
            this.newEnrollmentDependentRefId = null;
        },
        newEnrollmentButtonToggle() {
            this.isNewEnrollment = true;

            if (this.schoolAccountsOptions.length === 1){
                this.selectedSchool = this.schoolAccountsOptions[0]
                this.schoolDocument = this.schoolAccountsOptions[0]?.school_document;
            }
        },
        enrollmentPayload() {
            let financialFields = {
                tuition: null,
                total_tuition: null,
                tuition_due_date: null,
                installment_number: null,
                total_discount_is_percentage: null,
                total_discount: null,
                discount: null,
                punctuality_discount_rule: null,
                cost_center: null,
                extension_session_enrollment_total_value: null,
                extension_session_enrollment_value: null,
            }

            if(this.showFinancialFields){
                financialFields = {
                    tuition: this.moneyMaskToDecimal(this.tuition),
                    total_tuition: this.moneyMaskToDecimal(this.totalTuition),
                    tuition_due_date: this.tuitionDueDate,
                    installment_number: this.installmentNumber,
                    total_discount_is_percentage: this.totalDiscountIsPercentage,
                    total_discount: this.totalDiscountIsPercentage ? this.totalDiscountInPercentage : this.moneyMaskToDecimal(this.totalDiscount),
                    discount: this.moneyMaskToDecimal(this.discount),
                    punctuality_discount_rule: this.punctualityDiscountRuleEqualToDueDate ? this.tuitionDueDate : this.punctualityDiscountRule,
                    cost_center: this.costCenter,
                    extension_session_enrollment_total_value: this.selectedSchool?.extension_session_option_enable && this.extensionSessionEnable ? this.moneyMaskToDecimal(this.extensionSessionEnrollmentTotalValue) : null,
                    extension_session_enrollment_value: this.selectedSchool?.extension_session_option_enable && this.extensionSessionEnable ? this.moneyMaskToDecimal(this.extensionSessionEnrollmentValue) : null,
                }
            }

            return ({
                ...financialFields,
                contactId: this.contact.id,
                number: this.number,

                school_class: this.schoolClass,
                student_school_class: this.schoolClass,
                
                schooling_type: this.schoolingType.VALUE,
                student_schooling_type: this.schoolingType.VALUE,

                school_year: this.schoolYear,

                student_name: this.studentName,
                contract_student_name: this.studentName,

                student_first_document: this.studentFirstDocument,
                contract_student_first_document: this.studentFirstDocument,

                student_date_of_birth: this.studentDateOfBirth,

                responsible_name: this.responsibleName,
                responsible_zip_code: this.responsibleZipCode,
                responsible_state: this.responsibleState.ID,
                responsible_city: this.responsibleCity,
                responsible_district: this.responsibleDistrict,
                responsible_street: this.responsibleStreet,
                responsible_building_number: this.responsibleBuildingNumber,
                responsible_first_document: this.responsibleFirstDocument,

                enrollment_school_document: this.schoolDocument,
                extension_session_enable: this.extensionSessionEnable,

                user_id: this.currentUserId,
                
                student_schooling_type: this.schoolingType.VALUE,
                enrollment_exempt: this.enrollmentExempt
            });
        },
        async updateEnrollment() {
            const payload = this.enrollmentPayload();
            try {
                await this.$store.dispatch('schoolEnrollments/update', { id: this.enrollment.id, ...payload });
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                if(error.cause.number){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.UNIQUENESS_ERROR_MESSAGE'));
                } else if(error.cause.student_first_document){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.STUDENT_DOCUMENT_UNIQUENESS_ERROR_MESSAGE'));
                } else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.ERROR_MESSAGE'));
                }
            }
        },
        async createEnrollment() {
            const payload = { ...this.enrollmentPayload(), keducore: { dependent_ref_id: this.newEnrollmentDependentRefId}};
            try {
                await this.$store.dispatch('schoolEnrollments/create', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                if(error.cause.attributes.find(item => item === 'number')){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.UNIQUENESS_ERROR_MESSAGE'));
                } else if(error.cause.attributes.find(item => item === 'student_first_document')){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.STUDENT_DOCUMENT_UNIQUENESS_ERROR_MESSAGE'));
                }  else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.ERROR_MESSAGE'));
                }
            }
        },
        async lookFinancialOwner() {
            this.searchForDependentsLoading = true;
            try {
                this.searchOverdueBill();
                if(!this.enrollment) this.searchForDependents();
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.FETCH_DEPENDENTS_ERROR'));
            } finally {
                this.searchForDependentsLoading = false;
            }
        },
        async searchForDependents() {
            const response = await SchoolEnrollmentApi.searchForDependents(this.contact.id, this.schoolDocument, this.responsibleFirstDocument);

            const { dependents, responsible } = response.data;

            this.responsibleName = responsible.responsible_name || '';
            this.hasResponsibleName = !!responsible.responsible_name;

            this.responsibleZipCode = responsible.responsible_zip_code || '';
            this.responsibleState = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => responsible.responsible_state === option.ID) || '';
            this.responsibleCity = responsible.responsible_city || '';
            this.responsibleDistrict = responsible.responsible_district || '';
            this.responsibleStreet = responsible.responsible_street || '';
            this.responsibleBuildingNumber = responsible.responsible_building_number || '';

            this.dependents = dependents.map(dependent => ({...dependent, label: dependent.student_name}));
        },
        async searchOverdueBill() {
            const payload = {
                contact_id: this.contact.id,
                student_name: this.studentName,
                student_first_document: this.studentFirstDocument,
                responsible_name: this.responsibleName,
                responsible_first_document: this.responsibleFirstDocument,
                school_document: this.schoolDocument,
                school_name: this.selectedSchool.school_name,
                user_id: this.currentUserId
            }

            const response = await SchoolEnrollmentApi.searchOverdueBill(payload);
            this.overdueBills = response.data;

            if(this.isDefaulterResponsibleWithKedu){
                this.showDefaulterModal = true;
            }
        },

        async handleSearchOverdueBill() {
            try {
                this.searchOverdueBill();
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_OVERDUE_BILL.SUCCESS_MESSAGE'));
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_OVERDUE_BILL.ERROR_MESSAGE'));
            }
        },

        async syncWithKeducore() {
            try {
                const response = await SchoolEnrollmentApi.syncWithKeducore(this.contact.id, this.enrollment.id);
                const {dependent, financial_owner} = response.data;

                this.studentName = dependent.name;
                this.studentFirstDocument = this.cpfMask(dependent.cpf);
                this.number = dependent.enrollments[0].registry;
                this.responsibleName = financial_owner.name;
                this.responsibleFirstDocument = this.cpfMask(financial_owner.cpf);

                const address = financial_owner.address;

                this.responsibleZipCode = address.zipCode || '';
                this.responsibleState = (address.state && this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => address.state === option.ID)) || '';

                this.responsibleCity = address.city || '';
                this.responsibleDistrict = address.district || '';
                this.responsibleStreet = address.street || '';
                this.responsibleBuildingNumber = address.number|| '';

                
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SYNC_WITH_KECUORE.SUCCESS_MESSAGE'));
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SYNC_WITH_KECUORE.ERROR_MESSAGE'));
            }
        },

        handleSubmitEnrollmentForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if (this.isNewEnrollment) {
                this.createEnrollment();
            } else {
                this.updateEnrollment();
            }
        },

        chooseEnrollment(selectedEnrollment) {
            this.isNewEnrollment = false;
            this.schoolDocument = selectedEnrollment.enrollment_school_document;
            this.selectedSchool = this.currentAccount?.school_accounts?.find(school => school.school_document === this.schoolDocument);
            this.enrollmentExempt = selectedEnrollment?.enrollment_exempt;
            
            this.number = selectedEnrollment.number || '';
            this.schoolClass = selectedEnrollment.school_class || '';
            this.schoolingType = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS').find(option => selectedEnrollment.schooling_type === option.VALUE) || '';
            this.schoolYear = selectedEnrollment.school_year || '';
            this.studentName = selectedEnrollment.student_name || '';

            this.studentFirstDocument = selectedEnrollment.student_first_document || '';
            this.hasStudentFirstDocument = !!selectedEnrollment.student_first_document;

            this.studentDateOfBirth = selectedEnrollment.school_contract?.student_date_of_birth || '';

            this.responsibleName = selectedEnrollment.responsible_name || '';
            this.responsibleZipCode = selectedEnrollment.responsible_zip_code || '';
            this.responsibleState = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => selectedEnrollment.responsible_state === option.ID) || '';

            this.responsibleCity = selectedEnrollment.responsible_city || '';
            this.responsibleDistrict = selectedEnrollment.responsible_district || '';
            this.responsibleStreet = selectedEnrollment.responsible_street || '';
            this.responsibleBuildingNumber = selectedEnrollment.responsible_building_number || '';
            this.responsibleFirstDocument = selectedEnrollment.responsible_first_document || '';

            this.tuition = selectedEnrollment.tuition && parseFloat(selectedEnrollment.tuition).toFixed(2) || '';
            this.totalTuition = selectedEnrollment.total_tuition && parseFloat(selectedEnrollment.total_tuition).toFixed(2) || '';
            this.tuitionDueDate = selectedEnrollment.tuition_due_date || '';
            this.installmentNumber = selectedEnrollment.installment_number || '';

            this.extensionSessionEnable = selectedEnrollment.extension_session_enable;
            this.extensionSessionEnrollmentTotalValue = selectedEnrollment?.extension_session_enrollment_total_value && parseFloat(selectedEnrollment.extension_session_enrollment_total_value).toFixed(2) || '';
            this.extensionSessionEnrollmentValue = selectedEnrollment?.extension_session_enrollment_value && parseFloat(selectedEnrollment.extension_session_enrollment_value).toFixed(2) || '';

            if (selectedEnrollment.total_discount_is_percentage) {
                this.totalDiscountIsPercentage = true;
                this.totalDiscountInPercentage = selectedEnrollment.total_discount || ''
            } else {
                this.totalDiscountIsPercentage = false;
                this.totalDiscount = selectedEnrollment.total_discount && parseFloat(selectedEnrollment.total_discount).toFixed(2) || ''
            }
     
            this.discount = selectedEnrollment.discount && parseFloat(selectedEnrollment.discount).toFixed(2) || '';
            this.punctualityDiscountRule = selectedEnrollment.punctuality_discount_rule || '';
            this.costCenter = selectedEnrollment.costCenter;
            this.punctualityDiscountRuleEqualToDueDate = (selectedEnrollment.punctuality_discount_rule === selectedEnrollment.tuition_due_date)
        },

        chooseDependent(selectedDependent) {
            const {label, ...enrollment} = selectedDependent;

            this.newEnrollmentDependentRefId = selectedDependent.ref_id;
            this.studentName = enrollment.student_name || '';
            this.studentFirstDocument = enrollment.student_first_document || '';
            this.hasStudentFirstDocument = !!enrollment.student_first_document;
            this.number = enrollment.number || '';
        },

        chooseSchool(selectedSchool) {
            this.schoolDocument = selectedSchool.school_document;
            this.schoolClass = null;
            this.schoolingType = null;
            this.schoolYear = null;
        },

        chooseSchoolingType(){
            this.schoolClass = null;
        },

        async fetchLabels() {
            if (!this.contact.id) {
                return;
            }
            this.$store.dispatch('contactLabels/get', this.contact.id);
        },
        validateMoneyGreaterThanZero(moneyValue) {
            if (this.moneyMaskToDecimal(moneyValue) <= 0) {
                return false 
            } 
            return true;
        },

        roundMoneyToFloor(value) {
            return (Math.floor(value * 100) / 100).toFixed(2);
        },

        async searchResponsibleZipCode () {
            try {
                const response = await this.fetchZipCode(this.responsibleZipCode);
                const {data} = response;

                this.responsibleState = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => option.ID === data.uf);
                this.responsibleCity = data.localidade;
                this.responsibleDistrict = data.bairro;
                this.responsibleStreet = data.logradouro;
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_ZIPCODE.API_ERROR'));
            }
        },
        handleDefaulterSendMessageButton() {
            this.showDefaulterModal = false;
            this.onCancel();
            this.sendDefaulterMessage();
        },
        handleDefaulterCancelButton() {
            this.showDefaulterModal = false;
            this.onCancel();
        },
        handleDefaulterContinueButton() {
            this.showDefaulterModal = false;
        },
        async sendDefaulterMessage() {
            const messagePayload = {
                conversationId: this.lastConversation.id,
                message: this.$t('SCHOOL_CONTACT_FORM.FORM.DEFAUTER_MODAL.DEFAULTER_MESSAGE_IN_CONVERSATION'),
                sender: {
                    name: this.currentUser.name,
                    thumbnail: this.currentUser.avatar_url,
                },
            };
            try {
                await this.$store.dispatch('createPendingMessageAndSend', messagePayload);
            } catch (error) {
                const errorMessage =
                error?.response?.data?.error || this.$t('CONVERSATION.MESSAGE_ERROR');
                this.showAlert(errorMessage);
            }
        },
        async searchDependentDocument(studentDocument) {
            try {
                const response = await SchoolEnrollmentApi.searchDependentDocument(this.contact.id, studentDocument, this.schoolDocument);
                this.number = response?.data?.enrollments[0]?.registry;
            } catch (error) {
                console.log("error",error);
            }
        }
    }
}

</script>
<styles scoped lang="scss">
.contact--form {
    padding: var(--space-normal) var(--space-large) var(--space-large);
    min-height: 200px;

    .columns {
        padding: 0 var(--space-smaller);
    }
}

.field-button {
    margin-top: -10px;
    display: flex;
    justify-content: end;
}

.add-button-area {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-bottom: 5px;
}

.add-button {
    height: 25px;
}

.enrollment-title {
    margin-bottom: 5px;
}

.overdue-alert {
    @apply border-yellow-300 dark:border-yellow-300
}
</styles>