<template>
  <div>
    <form>
      <div v-if="!showPendingDocumentAlert">
        <div class="merge-contacts">
          <div class="multiselect-wrap--medium" v-if="schoolContracts.length === 0">
            <label class="multiselect__label">
              {{ $t('SEND_CONTRACT.SELECT.EMPTY_LABEL') }}
            </label>
          </div>
          <div class="multiselect-wrap--medium" v-if="schoolContracts.length > 0">
            <label class="multiselect__label">
              {{ $t('SEND_CONTRACT.SELECT.TITLE') }}
            </label>
            <multiselect v-model="selectedEnrollment" :options="schoolContracts" label="student_name" track-by="id"
              :show-labels="false" :placeholder="$t('SEND_CONTRACT.SELECT.PLACEHOLDER')" :max-height="150"
              open-direction="top" />
            <div class="-mt-2">
              <span v-if="!$v.selectedEnrollment.hasFinishedDocument" class="error">
                {{ $t('SEND_CONTRACT.SELECT.HAS_FINISHED_DOCUMENT', {
                  year: selectedEnrollment &&
                    selectedEnrollment.school_year}) }}
              </span>
              <span v-else-if="!$v.selectedEnrollment.invalidEnrollmentDueDate" class="error">
                {{ $t('SEND_CONTRACT.SELECT.INVALID_ENROLLMENT_DUE_DATE') }}
              </span>
              <span v-else-if="!$v.selectedEnrollment.invalidContractDueDate" class="error">
                {{ $t('SEND_CONTRACT.SELECT.INVALID_CONTRACT_DUE_DATE') }}
              </span>
            </div>

          </div>

          <div class="multiselect-wrap--medium">
            <label class="multiselect__label">
              {{ $t('SEND_CONTRACT.SELECT_HOOK.TITLE') }}
            </label>
            <multiselect v-model="selectedClicksignHook" :options="clicksignHookOptions" label="name" track-by="id"
              :show-labels="false" :placeholder="$t('SEND_CONTRACT.SELECT_HOOK.PLACEHOLDER')" :max-height="150"
              open-direction="top" :disabled="!selectedEnrollment" />
          </div>
        </div>
        <div class="footer">
          <woot-button variant="clear" @click.prevent="onCancel">
            {{ $t('SEND_CONTRACT.FORM.CANCEL') }}
          </woot-button>
          <woot-button :is-loading="isRequestingContract" :disabled="$v.$invalid" @click.prevent="handleCheckDocuments">
            {{ $t('SEND_CONTRACT.FORM.SUBMIT') }}
          </woot-button>
        </div>
      </div>
      <div v-if="showPendingDocumentAlert">
        <div class="text-sm text-red-400 mb-2">
          <span v-dompurify-html="$t('SEND_CONTRACT.SCHOOL_DOCUMENT_UNFINISHED')" />
        </div>

        <div class="flex-end gap-2">
          <woot-button :is-loading="isRequestingContract" :disabled="$v.$invalid" @click.prevent="onSubmit">
            {{ $t('SEND_CONTRACT.FORM.YES') }}
          </woot-button>

          <woot-button :is-loading="isRequestingContract" :disabled="$v.$invalid" @click.prevent="onCancel">
            {{ $t('SEND_CONTRACT.FORM.CANCEL') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {},
  mixins: [alertMixin],
  props: {
    isRequestingContract: {
      type: Boolean,
      default: false,
    },
    enrollments: {
      type: Array,
      default: () => [],
    },
  },
  validations() {
    return {
      selectedEnrollment: {
        required,
        invalidEnrollmentDueDate: this.enrollmentDueDateValidation,
        invalidContractDueDate: this.contractDueDateValidation,
        hasFinishedDocument: this.hasCompletedSchoolDocumentForTheYear,
      }
    }
  },
  data() {
    return {
      selectedEnrollment: null,
      selectedClicksignHook: null,
      showPendingDocumentAlert: false,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    schoolContracts() {
      return this.enrollments.filter(enrollment => enrollment?.school_contract?.annuity);
    },
    schoolDocuments() {
      return this.selectedEnrollment?.school_documents;
    },
    hasPendingSchoolDocument() {
      return this.schoolDocuments.filter(document => document.status === "pending").length > 0;
    },
    schoolAccount() {
      return this.getAccount(this.accountId).school_accounts?.find(school_account => school_account.school_document === this.selectedEnrollment?.enrollment_school_document);
    },
    clicksignAccountIntegrations() {
      return this.$store.getters['integrations/getIntegration']('clicksign');
    },
    clicksignHookOptions() {
      const options = this.clicksignAccountIntegrations?.hooks?.filter(hook => !hook?.settings?.school_account_id || (hook?.settings?.school_account_id === `${this.schoolAccount?.id}`)) || [];
      return options.map(item => ({ ...item, name: item.settings?.name }))
    }
  },
  methods: {
    handleCheckDocuments() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.hasPendingSchoolDocument) {
        this.showPendingDocumentAlert = true;
      } else {
        this.onSubmit();
      }
    },

    onSubmit() {
      this.$emit('submit', { ...this.selectedEnrollment, integrationHook: this.selectedClicksignHook });
    },

    onCancel() {
      this.selectedEnrollment = null;
      this.showPendingDocumentAlert = false;
      this.$emit('cancel');
    },
    getNextBusinessDay(date) {
      const nextDay = new Date(date);
      nextDay.setDate(date.getDate() + 1);

      while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
        nextDay.setDate(nextDay.getDate() + 1);
      }

      return nextDay;
    },
    enrollmentDueDateValidation(enrollment) {
      if (!enrollment || !this.schoolAccount.generate_enrollment_bills_enable || 
        (this.schoolAccount.allow_enrollment_exemption && enrollment.enrollment_exempt)) return true;

      if (enrollment?.tuition_due_date) {
        const enrollmentDueDate = new Date(enrollment.tuition_due_date + 'T00:00:00-03:00');
        const currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);

        const firstBusinessDayAfterToday = this.getNextBusinessDay(currentDay);
        if (enrollmentDueDate >= firstBusinessDayAfterToday) {
          return true;
        }
      }
      return false;
    },
    contractDueDateValidation(enrollment) {
      if (!enrollment) return true;

      if (enrollment?.school_contract?.start_date_installment) {
        const enrollmentDueDate = new Date(enrollment.school_contract.start_date_installment + 'T00:00:00-03:00');
        const currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);

        const firstBusinessDayAfterToday = this.getNextBusinessDay(currentDay);
        if (enrollmentDueDate >= firstBusinessDayAfterToday) {
          return true;
        }
      }
      return false;
    },
    hasCompletedSchoolDocumentForTheYear(enrollment) {
      if (!enrollment) return true;

      return !enrollment.school_documents?.filter(document => document.status === "completed" && document.document_year == enrollment.school_year).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: var(--space-medium);
  display: flex;
  justify-content: flex-end;
}
.error {
  @apply text-sm text-red-300 dark:text-red-500;
}
</style>