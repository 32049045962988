<template>
    <form class="contact--form" @submit.prevent="handleSubmitEnrollmentForm">
        <div class="row" v-if="!enrollment || !contractType">
            <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.LABEL') }}
            </label>
            <multiselect v-model="enrollment" track-by='id' label="student_name"
                :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.PLACEHOLDER')" selected-label
                :select-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.REMOVE')" :max-height="160" :options="enrollments"
                :allow-empty="true" :option-height="104" @select="checkEnrollment" />

            <label v-if="contractTypes.length > 1">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_TYPE.LABEL') }}
            </label>
            <multiselect v-if="contractTypes.length > 1"
                v-model="contractType"
                :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_TYPE.PLACEHOLDER')"
                :select-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_TYPE.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_TYPE.REMOVE')"
                :allow-empty="false" :options="contractTypes" @select="() => chooseEnrollment(this.enrollment)" />
        </div>

        <div v-if="enrollment
            && contractType==='Digital'">
            <div class="medium-12 columns enrollment-title">
                <label class="page-sub-title" style="font-size: medium; font-weight: bold;">
                    {{ `${$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT.TITLE')} - ${enrollment.student_name}` }}
                </label>
            </div>

            <label :class="{ error: $v.contractorName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.LABEL') }}
                <input v-model.trim="contractorName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.PLACEHOLDER')"
                    @input="$v.contractorName.$touch" />
                <span v-if="$v.contractorName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.LABEL') }}
                <input v-model="contractorZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.contractorZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.contractorZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
                <div class="medium-12 field-button">
                    <woot-button variant="clear" @click.prevent="searchContractorZipCode" size="small">
                        {{ this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_ZIPCODE.BUTTON_LABEL') }}
                    </woot-button>
                </div>
            </label>

            <label :class="{ error: $v.contractorAddress.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.LABEL') }}
                <input v-model="contractorAddress" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.PLACEHOLDER')"
                    @input="$v.contractorAddress.$touch" />
                <span v-if="$v.contractorAddress.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.LABEL') }}
                <input v-model="contractorDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.PLACEHOLDER')"
                    @input="$v.contractorDistrict.$touch" />
                <span v-if="$v.contractorDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.contractorState.$error }">
                <label >
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.LABEL') }}
                </label>
                <multiselect v-model="contractorState" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.contractorState.$touch" />
                <span v-if="$v.contractorState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.contractorCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.LABEL') }}
                <input v-model="contractorCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.PLACEHOLDER')"
                    @input="$v.contractorCity.$touch" />
                <span v-if="$v.contractorCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorPhoneNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.LABEL') }}
                <input v-model="contractorPhoneNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.PLACEHOLDER')"
                    @input="$v.contractorPhoneNumber.$touch" v-mask="'(##) #########'" />
                <span v-if="$v.contractorPhoneNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorEmail.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.LABEL') }}
                <input v-model="contractorEmail" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.PLACEHOLDER')"
                    @input="$v.contractorEmail.$touch" />
                <span v-if="$v.contractorEmail.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorFirstDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.LABEL') }}
                <input v-model="contractorFirstDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.contractorFirstDocument.$touch" v-mask="'###.###.###-##'" />

                <span v-if="$v.contractorFirstDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorSecondDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.LABEL') }}
                <input v-model="contractorSecondDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.PLACEHOLDER')"
                    @input="$v.contractorSecondDocument.$touch" />
                <span v-if="$v.contractorSecondDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorBirthDate.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.LABEL') }}
                <input v-model.trim="contractorBirthDate" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.PLACEHOLDER')"
                    @input="$v.contractorBirthDate.$touch" :max="maxBirthDate" />

                <span v-if="$v.contractorBirthDate.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorOccupation.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.LABEL') }}
                <input v-model="contractorOccupation" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.PLACEHOLDER')"
                    @input="$v.contractorOccupation.$touch" />

                <span v-if="$v.contractorOccupation.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.contractorMaritalStatus.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.LABEL') }}
                </label>
                <multiselect v-model="contractorMaritalStatus"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.REMOVE')" :max-height="160"
                    :options="this.$t('SCHOOL_CONTACT_FORM.FORM.MARITAL_STATUS.STATUS')" :allow-empty="true"
                    :option-height="104" @input="$v.contractorMaritalStatus.$touch" />

                <span v-if="$v.contractorMaritalStatus.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.ERROR_MESSAGE') }}
                </span>
            </div>

            <div class="row" :class="{ error: $v.relationshipWithStudent.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.LABEL') }}
                </label>
                <multiselect v-model="relationshipWithStudent" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.relationshipWithStudent.$touch" />

                <span v-if="$v.relationshipWithStudent.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.ERROR_MESSAGE') }}
                </span>
            </div>

            <div class="row" :class="{ error: $v.studentParentsMaritalStatus.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.LABEL') }}
                </label>
                <multiselect v-model="studentParentsMaritalStatus" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentParentsMaritalStatus.$touch" />
                
                <span v-if="$v.studentParentsMaritalStatus.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="studentParentsMaritalStatus.ID === 'OTHER'" :class="{ error: $v.studentParentsMaritalStatusOther.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.LABEL') }}
                <input v-model="studentParentsMaritalStatusOther" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.PLACEHOLDER')"
                    @input="$v.studentParentsMaritalStatusOther.$touch" />

                <span v-if="$v.studentParentsMaritalStatusOther.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" v-if="studentParentsMaritalStatus.ID === 'DIVORCED'" :class="{ error: $v.studentCustodyArrangement.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.LABEL') }}
                </label>
                <multiselect v-model="studentCustodyArrangement" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentCustodyArrangement.$touch" />

                <span v-if="$v.studentCustodyArrangement.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.studentName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.LABEL') }}
                <input v-model="studentName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.PLACEHOLDER')"
                    @input="$v.studentName.$touch" />
                <span v-if="$v.studentName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.studentFirstDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_FIRST_DOCUMENT.LABEL') }}
                <input v-model="studentFirstDocument" type="text" :disabled="true"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.studentFirstDocument.$touch" v-mask="'###.###.###-##'" />

                <span v-if="$v.studentFirstDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.studentDateOfBirth.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.LABEL') }}
                <input v-model.trim="studentDateOfBirth" type="date" :disabled="true"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.PLACEHOLDER')"
                    @input="$v.studentDateOfBirth.$touch" />
                <span v-if="$v.studentDateOfBirth.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.studentSchoolingType.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.LABEL') }}
                </label>
                <multiselect v-model="studentSchoolingType" track-by="VALUE" label="LABEL" :disabled="true"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentSchoolingType.$touch" />

                <span v-if="$v.studentSchoolingType.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.studentSchoolClass.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.LABEL') }}
                <input v-model="studentSchoolClass" type="text" :disabled="true"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.PLACEHOLDER')"
                    @input="$v.studentSchoolClass.$touch" />

                <span v-if="$v.studentSchoolClass.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.LABEL') }}
                <input v-model.trim="schoolName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.PLACEHOLDER')" @input="$v.schoolName.$touch" />
                <span v-if="$v.schoolName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolCorporateName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CORPORATE_NAME.LABEL') }}
                <input v-model.trim="schoolCorporateName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CORPORATE_NAME.PLACEHOLDER')" @input="$v.schoolCorporateName.$touch" />
                <span v-if="$v.schoolCorporateName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CORPORATE_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.LABEL') }}
                <input v-model.trim="schoolDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.PLACEHOLDER')"
                    @input="$v.schoolDocument.$touch" v-mask="'##.###.###/####-##'" />
                <span v-if="$v.schoolDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolAddress.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.LABEL') }}
                <input v-model.trim="schoolAddress" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.PLACEHOLDER')"
                    @input="$v.schoolAddress.$touch" />
                <span v-if="$v.schoolAddress.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.LABEL') }}
                <input v-model.trim="schoolDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.PLACEHOLDER')"
                    @input="$v.schoolDistrict.$touch" />
                <span v-if="$v.schoolDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.LABEL') }}
                <input v-model.trim="schoolCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.PLACEHOLDER')" @input="$v.schoolCity.$touch" />
                <span v-if="$v.schoolCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.schoolState.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.LABEL') }}
                </label>
                <multiselect v-model="schoolState" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.schoolState.$touch" />

                <span v-if="$v.schoolState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.schoolZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.LABEL') }}
                <input v-model.trim="schoolZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.schoolZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.schoolZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.schoolPhoneNumber.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.LABEL') }}
                </label>
                <input v-model.trim="schoolPhoneNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.PLACEHOLDER')"
                    @input="$v.schoolPhoneNumber.$touch" v-mask="'(##) #########'" />
                <span v-if="$v.schoolPhoneNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.schoolEmail.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.LABEL') }}
                <input v-model.trim="schoolEmail" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.PLACEHOLDER')" @input="$v.schoolEmail.$touch" />
                <span v-if="$v.schoolEmail.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.annuity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.LABEL') }}
                <input v-model.trim="annuity" type="text" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.PLACEHOLDER')"
                    @input="$v.annuity.$touch" v-mask="moneyMask" />
                <span v-if="$v.annuity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.installmentsQuantity.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_QUANTITY.LABEL') }}
                </label>
                <multiselect v-model="installmentsQuantity"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_QUANTITY.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_QUANTITY.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_QUANTITY.REMOVE')" :max-height="160"
                    :options="installmentsQuantityOptions" :allow-empty="true" :option-height="104" @input="$v.installmentsQuantity.$touch"/>
                <span v-if="$v.installmentsQuantity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_QUANTITY.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.installmentValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_VALUE.LABEL') }}
                <input v-model.trim="installmentValue" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_VALUE.PLACEHOLDER')"
                    @input="$v.installmentValue.$touch" v-mask="moneyMask" disabled />
                <span v-if="$v.installmentValue.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.installmentsMaturity.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.LABEL') }}
                </label>
                <multiselect v-model="installmentsMaturity"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.REMOVE')" :max-height="160"
                    :options="installmentMaturityOptions" :allow-empty="true" :option-height="104" @input="$v.installmentsMaturity.$touch" />
                <span v-if="$v.installmentsMaturity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.startDateInstallment.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT.LABEL') }}
                <input v-model.trim="startDateInstallment" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT.PLACEHOLDER')"
                    @input="$v.startDateInstallment.$touch"/>
                <span v-if="$v.startDateInstallment.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.lastDayInstallment.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT.LABEL') }}
                <input v-model.trim="lastDayInstallment" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT.PLACEHOLDER')"
                    @input="$v.lastDayInstallment.$touch" disabled/>
                <span v-if="$v.lastDayInstallment.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <div>
                <input v-model.trim="punctualityDiscountIsPercentage" type="checkbox" />
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_IS_PERCENTAGE.LABEL') }}
                </label>
            </div>

            <label v-if="punctualityDiscountIsPercentage" :class="{ error: $v.punctualityDiscountPercentage.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.LABEL') }}
                <input v-model.trim="punctualityDiscountPercentage" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.PLACEHOLDER')"
                    @input="$v.punctualityDiscountPercentage.$touch" />
                <span v-if="$v.punctualityDiscountValue.$error || $v.punctualityDiscountPercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-else :class="{ error: $v.punctualityDiscountValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_VALUE.LABEL') }}
                <input v-model.trim="punctualityDiscountValue" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_VALUE.PLACEHOLDER')"
                    @input="$v.punctualityDiscountValue.$touch" v-mask="moneyMask"/>
                <span v-if="$v.punctualityDiscountValue.$error || $v.punctualityDiscountPercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label>
                <input v-model.trim="punctualityDiscountRuleEqualToDueDate" type="checkbox" />
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE_EQUAL_TO_DUE_DATE.LABEL') }}
            </label>

            <label v-if="!punctualityDiscountRuleEqualToDueDate" :class="{ error: $v.contractPunctualityDiscountRule.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.LABEL') }}
                <input v-model.trim="contractPunctualityDiscountRule" type="date" :max="startDateInstallment"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.PLACEHOLDER')"
                    @input="$v.contractPunctualityDiscountRule.$touch" />
                <span v-if="$v.contractPunctualityDiscountRule.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div>
                <input v-model.trim="withdrawalFeeIsPercentage" type="checkbox" />
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_IS_PERCENTAGE.LABEL') }}
                </label>
            </div>

            <label v-if="withdrawalFeeIsPercentage" :class="{ error: $v.withdrawalFeePercentage.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.LABEL') }}
                <input v-model.trim="withdrawalFeePercentage" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.PLACEHOLDER')"
                    @input="$v.withdrawalFeePercentage.$touch" step="0.01" min="0" />
                <span v-if="$v.withdrawalFeePercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-else :class="{ error: $v.withdrawalFeeValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_VALUE.LABEL') }}
                <input v-model.trim="withdrawalFeeValue" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_VALUE.PLACEHOLDER')"
                    @input="$v.withdrawalFeeValue.$touch"  v-mask="moneyMask"/>
                <span v-if="$v.withdrawalFeeValue.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionAnnuity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ANNUITY.LABEL') }}
                <input v-model.trim="extensionSessionAnnuity" type="text" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ANNUITY.PLACEHOLDER')"
                    @input="$v.extensionSessionAnnuity.$touch" v-mask="moneyMask" />
                <span v-if="$v.extensionSessionAnnuity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ANNUITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionInstallmentValue.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_INSTALLMENT_VALUE.LABEL') }}
                <input v-model.trim="extensionSessionInstallmentValue" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_INSTALLMENT_VALUE.PLACEHOLDER')"
                    @input="$v.extensionSessionInstallmentValue.$touch" v-mask="moneyMask" disabled />
                <span v-if="$v.extensionSessionInstallmentValue.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_INSTALLMENT_VALUE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div v-if="extensionSessionEnable" class="row" :class="{ error: $v.extensionSessionShift.$error }">
                <label >
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.LABEL') }}
                </label>
                <multiselect v-model="extensionSessionShift"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.extensionSessionShift.$touch" />
                <span v-if="$v.extensionSessionShift.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_SHIFT.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionStart.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_START.LABEL') }}
                <input v-model.trim="extensionSessionStart" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_START.PLACEHOLDER')"
                    @input="$v.extensionSessionStart.$touch" step="1" min="0" max="23" />
                <span v-if="$v.extensionSessionStart.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_START.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionEnd.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_END.LABEL') }}
                <input v-model.trim="extensionSessionEnd" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_END.PLACEHOLDER')"
                    @input="$v.extensionSessionEnd.$touch" step="1" min="0" max="23" />
                <span v-if="$v.extensionSessionEnd.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_END.ERROR_MESSAGE') }}
                </span>
            </label>

            <div v-if="extensionSessionEnable" class="row" :class="{ error: $v.extensionSessionMeals.$error }">
                <label >
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.LABEL') }}
                </label>
                <multiselect v-model="extensionSessionMeals"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.SELECT_PLACEHOLDER')" :multiple="true" 
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.extensionSessionMeals.$touch" />
                <span v-if="$v.extensionSessionMeals.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MEALS.ERROR_MESSAGE') }}
                </span>
            </div>

            <div v-if="extensionSessionEnable" class="row" :class="{ error: $v.extensionSessionWeekdays.$error }">
                <label >
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.LABEL') }}
                </label>
                <multiselect v-model="extensionSessionWeekdays"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.SELECT_PLACEHOLDER')" :multiple="true" 
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.extensionSessionWeekdays.$touch" />
                <span v-if="$v.extensionSessionWeekdays.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionChangeDeadline.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_CHANGE_DEADLINE.LABEL') }}
                <input v-model.trim="extensionSessionChangeDeadline" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_CHANGE_DEADLINE.PLACEHOLDER')"
                    @input="$v.extensionSessionChangeDeadline.$touch" step="1" min="1" max="31" />
                <span v-if="$v.extensionSessionChangeDeadline.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_CHANGE_DEADLINE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionMinimumMealDays.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MINIMUM_MEAL_DAYS.LABEL') }}
                <input v-model.trim="extensionSessionMinimumMealDays" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MINIMUM_MEAL_DAYS.PLACEHOLDER')"
                    @input="$v.extensionSessionMinimumMealDays.$touch" step="1" min="0" max="5" />
                <span v-if="$v.extensionSessionMinimumMealDays.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MINIMUM_MEAL_DAYS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionMinAbsenceDaysForMealDiscount.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MIN_ABSENCE_DAYS_FOR_MEAL_DISCOUNT.LABEL') }}
                <input v-model.trim="extensionSessionMinAbsenceDaysForMealDiscount" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MIN_ABSENCE_DAYS_FOR_MEAL_DISCOUNT.PLACEHOLDER')"
                    @input="$v.extensionSessionMinAbsenceDaysForMealDiscount.$touch" step="1" min="0" />
                <span v-if="$v.extensionSessionMinAbsenceDaysForMealDiscount.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_MIN_ABSENCE_DAYS_FOR_MEAL_DISCOUNT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="extensionSessionEnable" :class="{ error: $v.extensionSessionAbsenceCutoffDayForMealDiscount.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ABSENCE_CUTOFF_DAY_FOR_MEAL_DISCOUNT.LABEL') }}
                <input v-model.trim="extensionSessionAbsenceCutoffDayForMealDiscount" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ABSENCE_CUTOFF_DAY_FOR_MEAL_DISCOUNT.PLACEHOLDER')"
                    @input="$v.extensionSessionAbsenceCutoffDayForMealDiscount.$touch" step="1" min="0" />
                <span v-if="$v.extensionSessionAbsenceCutoffDayForMealDiscount.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_ABSENCE_CUTOFF_DAY_FOR_MEAL_DISCOUNT.ERROR_MESSAGE') }}
                </span>
            </label>

            <section>
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.LABEL') }}
                </label>
                <div class="medium-12 columns filters-wrap">
                    <div class="filters">
                        <div>
                            <div v-if="witnesses.length === 0">
                                <label class="filter-none">
                                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NO_HAVE_WITNESS') }}
                                </label>
                            </div>
                            <div v-else>
                                <div class="filter" v-for="(field, index) in witnesses" :key="index">
                                    <label class="filter-title">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.HEADER_TITLE') }} {{ index + 1 }}:
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].name.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NAME_LABEL') }}
                                        <input v-model.trim="field.name" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NAME_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].name.$touch" />
                                        <span v-if="$v.witnesses.$each[index].name.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_NAME_MESSAGE') }}
                                        </span>
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].phone.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.PHONE_LABEL') }}
                                        <input v-model.trim="field.phone" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.PHONE_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].phone.$touch" v-mask="'(##) #########'" />
                                        <span v-if="$v.witnesses.$each[index].phone.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_PHONE_MESSAGE') }}
                                        </span>
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].email.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.EMAIL_LABEL') }}
                                        <input v-model.trim="field.email" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.EMAIL_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].email.$touch" />
                                        <span v-if="$v.witnesses.$each[index].email.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_EMAIL_MESSAGE') }}
                                        </span>
                                    </label>
                                    <div class="filter-delete">
                                      <woot-button
                                        icon="delete"
                                        color-scheme="alert"
                                        variant="smooth"
                                        size="small"
                                        @click.prevent="removeWitness(index)">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.REMOVE_BUTTON') }}
                                      </woot-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-add">
                        <woot-button
                            icon="add"
                            color-scheme="success"
                            variant="smooth"
                            size="small"
                            @click.prevent="addWitness()">
                                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ADD_BUTTON') }}
                        </woot-button>
                    </div>
                </div>
            </section>
            <div class="modal-footer">
                <div class="medium-12 columns">
                    <woot-submit-button :disabled="inProgress || uiFlags.isCreating || uiFlags.isUpdating || uiFlags.isFetching" :button-text="$t('SCHOOL_CONTACT_FORM.FORM.SUBMIT')" />
                    <button class="button clear" @click.prevent="onCancel">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CANCEL') }}
                    </button>
                </div>
            </div>
        </div>
        <div>
            <section v-if="enrollment && contractType === 'Manual'">
                <section>
                    <label >
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.LABEL') }}
                    </label>
                    <div class="medium-12 row file-section-wrap">
                        <label v-if="!contractFiles || contractFiles.length === 0" class="medium-12 file-section-empty-label">
                                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.EMPTY_LABEL') }}
                        </label>
                        <div v-for="(contractFile, index) in contractFiles" class="medium-12 row file-section-item" >
                            <div v-if="contractFile && contractFile.data_url" class="medium-12 flex items-center justify-center">
                                <attachment-box 
                                    :url="contractFile.data_url"
                                />
                            </div>
                            <div class="medium-12 items-center flex justify-center">
                                <woot-button
                                    v-if="contractFile && contractFile.data_url"
                                    icon="delete"
                                    color-scheme="alert"
                                    variant="smooth"
                                    size="small"
                                    @click.prevent="removeFile(contractFile.id)"
                                >
                                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.REMOVE_BUTTON_LABEL') }}
                                </woot-button>
                            </div>
                        </div>
                        <input type="file"
                            ref="fileInput" 
                            accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"
                            @input="handleAttachFile"
                            style="display: none;"
                        />
                        <woot-button
                            v-if = "!contractFiles || contractFiles.length < 5"
                            class = "file-section-upload-button"
                            icon="upload"
                            color-scheme="primary"
                            variant="smooth"
                            size="small"
                            @click.prevent="openFileInput()"
                        >
                            {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.ADD_BUTTON_LABEL') }}
                        </woot-button>
                    </div>
                </section>
                <div class="modal-footer">
                
            </div>
            </section>
        </div>
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import maskMixin from 'shared/mixins/inputMaskMixin';
import zipCodeMixin from 'shared/mixins/zipCodeMixin';
import { isKeduFieldValid } from 'shared/helpers/KeduValidators';
import AttachmentBox from 'dashboard/components/widgets/conversation/bubble/File.vue';

export default {
    props: {
        contact: {
            type: Object,
            default: () => ({}),
        },
        inProgress: {
            type: Boolean,
            default: false,
        },
        selectedSchoolLabels: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        AttachmentBox
    },
    data() {
        return {
            enrollment: null,
            contractFiles: [],
            installmentsQuantity: null,

            contractorName: '',
            contractorAddress: '',
            contractorDistrict: '',
            contractorState: '',
            contractorCity: '',
            contractorZipCode: '',
            contractorPhoneNumber: '',
            contractorEmail: '',
            contractorFirstDocument: '',
            contractorSecondDocument: '',
            contractorBirthDate: '',
            contractorOccupation: '',
            contractorMaritalStatus: '',

            relationshipWithStudent: '',
            studentParentsMaritalStatus: '',
            studentParentsMaritalStatusOther: '',
            studentCustodyArrangement: '',

            studentName: '',
            studentFirstDocument: '',
            studentDateOfBirth: '',
            studentSchoolingType: '',
            studentSchoolClass: '',

            schoolCity: '',
            schoolEmail: '',
            schoolDocument: '',
            schoolName: '',
            schoolCorporateName: '',
            schoolDistrict: '',
            schoolPhoneNumber: '',
            schoolState: '',
            schoolAddress: '',
            schoolZipCode: '',

            installmentMaturityOptions: [5,10,15,20],
            installmentsQuantityOptions: [1,10,11,12,13],

            annuity: '',

            installmentsNumber: '',
            
            installmentsQuantity: '',
            installmentValue: '',
            startDateInstallment: '',
            lastDayInstallment: '',

            punctualityDiscountIsPercentage: true,
            punctualityDiscountPercentage: '',
            punctualityDiscountValue: '',
            contractPunctualityDiscountRule: '',
            withdrawalFeeIsPercentage: true,
            withdrawalFeePercentage: '',
            withdrawalFeeValue: '',
            installmentsMaturity: '',

            extensionSessionAbsenceCutoffDayForMealDiscount: '',
            extensionSessionAnnuity: '',
            extensionSessionChangeDeadline: '',
            extensionSessionStart: '',
            extensionSessionEnd: '',
            extensionSessionInstallmentValue: '',
            extensionSessionMeals: '',
            extensionSessionMinAbsenceDaysForMealDiscount: '',
            extensionSessionMinimumMealDays: '',
            extensionSessionShift: '',
            extensionSessionWeekdays: this.$t('SCHOOL_CONTACT_FORM.FORM.EXTENSION_SESSION_WEEKDAYS.OPTIONS'),

            witnesses: [],

            maxBirthDate: this.getMaxBirthDate(),
            contractType: null,
            contractTypes: [],
            extensionSessionEnable: false,
            punctualityDiscountRuleEqualToDueDate: false
        };
    },
    mixins: [alertMixin, maskMixin, zipCodeMixin],
    validations() {
           let validations = {
                contractorName: { required, keduValidator: this.checkDataValidation('fullname') },
                contractorAddress: { required },
                contractorDistrict: { required },
                contractorState: { required },
                contractorCity: { required, keduValidator: this.checkDataValidation('city') },
                contractorZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
                contractorPhoneNumber: { required, minLength: minLength(8), },
                contractorEmail: { required, email, },
                contractorFirstDocument: { required, keduValidator: this.checkDataValidation('firstDocument') },
                contractorSecondDocument: { minLength: minLength(4) },
                contractorBirthDate: { required },
                contractorOccupation: { required },
                contractorMaritalStatus: { required },

                relationshipWithStudent: { required },
                studentParentsMaritalStatus: { required },

                studentName: { required, keduValidator: this.checkDataValidation('fullname') },
                studentFirstDocument: { },
                studentDateOfBirth: { required },
                studentSchoolingType: { required },
                studentSchoolClass: { required },

                schoolName: { required, minLength: minLength(4), },
                schoolCorporateName: { required },
                schoolDocument: { required },
                schoolZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
                schoolState: { required },
                schoolCity: { required, keduValidator: this.checkDataValidation('city') },
                schoolDistrict: { required },
                schoolAddress: { required },
                schoolPhoneNumber: { required },
                schoolEmail: { required, email, },

                annuity: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },

                installmentsQuantity: { required },
                installmentValue: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                startDateInstallment: { required },
                lastDayInstallment: { required },

                punctualityDiscountPercentage: {},
                punctualityDiscountValue: {},
                withdrawalFeePercentage: {},
                withdrawalFeeValue: {},
                installmentsMaturity: { required },
                studentParentsMaritalStatusOther: {},
                studentCustodyArrangement: {},

                witnesses: {
                    $each: {
                        name: { required, keduValidator: this.checkDataValidation('fullname') },
                        phone: { minLength: minLength(8) },
                        email: { required, email },
                    },
                },

                extensionSessionAbsenceCutoffDayForMealDiscount: {},
                extensionSessionAnnuity: {},
                extensionSessionChangeDeadline: {},
                extensionSessionStart: {},
                extensionSessionEnd: {},
                extensionSessionInstallmentValue: {},
                extensionSessionMeals: {},
                extensionSessionMinAbsenceDaysForMealDiscount: {},
                extensionSessionMinimumMealDays: {},
                extensionSessionShift: {},
                extensionSessionWeekdays: {},

                contractPunctualityDiscountRule: {}
                
        };

        if(this.punctualityDiscountIsPercentage){
            validations = { ...validations, punctualityDiscountPercentage: { required }, punctualityDiscountValue: {}}
        } else {
            validations = { ...validations, punctualityDiscountPercentage: {}, punctualityDiscountValue: { required }}
        }

    
        if(this.withdrawalFeeIsPercentage){
            validations = { ...validations, withdrawalFeePercentage: { required },  withdrawalFeeValue: {}}
        } else {
            validations = { ...validations, withdrawalFeePercentage: {}, withdrawalFeeValue: { required }}
        }

        
        if (this.studentParentsMaritalStatus?.ID === "OTHER") {
            validations = { ...validations, studentParentsMaritalStatusOther: { required } }
        } else if (this.studentParentsMaritalStatus?.ID === "DIVORCED") {
            validations = { ...validations, studentCustodyArrangement: { required } }
        }

        if (this.extensionSessionEnable) {
            validations = { 
                ...validations,
                extensionSessionAbsenceCutoffDayForMealDiscount: { required },
                extensionSessionAnnuity: { required },
                extensionSessionChangeDeadline: { required },
                extensionSessionStart: { required },
                extensionSessionEnd: { required },
                extensionSessionInstallmentValue: { required },
                extensionSessionMeals: { required },
                extensionSessionMinAbsenceDaysForMealDiscount: { required },
                extensionSessionMinimumMealDays: { required },
                extensionSessionShift: { required },
                extensionSessionWeekdays: { required }
            }
        }

        if(!this.punctualityDiscountRuleEqualToDueDate) {
            validations = { 
                ...validations,
                contractPunctualityDiscountRule: { required, punctualityDiscountRuleGreaterThanTuitionDueDate: () => !( this.contractPunctualityDiscountRule > this.startDateInstallment) }
            }
        }

        return validations
    },
    computed: {
        ...mapGetters({
            enrollments: 'schoolEnrollments/getSchoolEnrollments',
            uiFlags: 'schoolEnrollments/getUIFlags',
            currentEnrollment:'schoolEnrollments/getEnrollment',
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
        }),
        currentAccount() {
            return this.getAccount(this.accountId);
        },
        checkDataValidation() {
            return (dataParams) => (value) => isKeduFieldValid(value, dataParams);
        },
        remainingMonths() {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0 for January, so we add 1
            const monthsInYear = 12;

            const numberOfRemainingMonths = monthsInYear - currentMonth;

            const remainingMonthsArray = Array.from({ length: numberOfRemainingMonths }, (_, i) => i + 1);
            
            return remainingMonthsArray;
        },
    },
    watch: {
        punctualityDiscountPercentage(val) {
            if (val > 90) {
                this.punctualityDiscountPercentage = 90;
            }
            this.punctualityDiscountValue = ((this.punctualityDiscountPercentage / 100) * this.moneyMaskToDecimal(this.installmentValue)).toFixed(2);
        },
        punctualityDiscountValue(val) {
            const limitValue = (Math.floor(this.moneyMaskToDecimal(this.installmentValue) * 90) / 100).toFixed(2);
            if (this.moneyMaskToDecimal(val) > limitValue) {
                this.punctualityDiscountValue = limitValue;
            }
        },
        annuity(val) {
            this.installmentValue = this.roundMoneyToFloor(this.moneyMaskToDecimal(val) / this.installmentsQuantity);
        },
        extensionSessionAnnuity(val) {
            this.extensionSessionInstallmentValue = this.roundMoneyToFloor(this.moneyMaskToDecimal(val) / this.installmentsQuantity);
        },
        installmentsQuantity(val) {
            this.installmentValue = this.roundMoneyToFloor(this.moneyMaskToDecimal(this.annuity) / val);

            const timestamp = new Date(this.startDateInstallment).setMonth(new Date(this.startDateInstallment).getMonth() + (this.installmentsQuantity - 1));
            this.lastDayInstallment = new Date(timestamp).toISOString().split('T')[0];
        },
        startDateInstallment(val) {
            const monthsToIncrease = this.installmentsQuantity ? (this.installmentsQuantity - 1) : 0;
            const lastDayTimestamp = new Date(val).setMonth(new Date(val).getMonth() + monthsToIncrease);

            this.startDateInstallment = this.fixDateMaturityDay(val);
            this.lastDayInstallment = this.fixDateMaturityDay(lastDayTimestamp);
        },
        installmentsMaturity(val) {
            this.startDateInstallment = this.fixDateMaturityDay(this.startDateInstallment);
            this.lastDayInstallment = this.fixDateMaturityDay(this.lastDayInstallment);
        }
    },
    mounted() {
        this.fetchLabels();
    },
    methods: {
        onCancel() {
            this.enrollment = null;
            this.extensionSessionEnable = false;
        },
        getStateById(id){
            return this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => id === option.ID);
        },
        getMaxBirthDate() {
            const currenteDate = new Date();
            const maxBirthDate = new Date(
                currenteDate.getFullYear() - 18,
                currenteDate.getMonth(),
                currenteDate.getDate()
            );
            return maxBirthDate.toISOString().split('T')[0];
        },
        enrollmentPayload() {
            return ({
                contactId: this.contact.id,

                contractor_name: this.contractorName,
                contractor_address: this.contractorAddress,
                contractor_district: this.contractorDistrict,
                contractor_state: this.contractorState.ID,
                contractor_city: this.contractorCity,
                contractor_zip_code: this.contractorZipCode,
                contractor_phone_number: this.contractorPhoneNumber,
                contractor_email: this.contractorEmail,
                contractor_first_document: this.contractorFirstDocument,
                contractor_second_document: this.contractorSecondDocument,
                contractor_birth_date: this.contractorBirthDate,
                contractor_occupation: this.contractorOccupation,
                contractor_marital_status: this.contractorMaritalStatus,

                relationship_with_student: this.relationshipWithStudent?.NAME,
                student_parents_marital_status: this.studentParentsMaritalStatus?.ID === "OTHER" ? this.studentParentsMaritalStatusOther : this.studentParentsMaritalStatus?.NAME,
                student_custody_arrangement: this.studentParentsMaritalStatus?.ID === "DIVORCED" && this.studentCustodyArrangement?.NAME,

                contract_student_name: this.studentName,
                contract_student_first_document: this.studentFirstDocument,
                student_date_of_birth: this.studentDateOfBirth,
                student_school_class: this.studentSchoolClass,
                student_schooling_type: this.studentSchoolingType?.VALUE,

                school_city: this.schoolCity,
                school_email: this.schoolEmail,
                school_document: this.schoolDocument,
                school_name: this.schoolName,
                school_corporate_name: this.schoolCorporateName,
                school_district: this.schoolDistrict,
                school_phone_number: this.schoolPhoneNumber,
                school_state: this.schoolState?.ID,
                school_address: this.schoolAddress,
                school_zip_code: this.schoolZipCode,

                annuity: this.moneyMaskToDecimal(this.annuity),

                installment_quantity: this.installmentsQuantity,
                installment_value: this.moneyMaskToDecimal(this.installmentValue) || null,
                start_date_installment: this.startDateInstallment || null,
                last_day_installment: this.lastDayInstallment || null,

                punctuality_discount_percentage: this.punctualityDiscountIsPercentage ? this.punctualityDiscountPercentage : null, 
                punctuality_discount_value: !this.punctualityDiscountIsPercentage ? this.moneyMaskToDecimal(this.punctualityDiscountValue || 0) : null,

                withdrawal_fee_percentage: this.withdrawalFeeIsPercentage ? this.withdrawalFeePercentage : null,
                withdrawal_fee_value: !this.withdrawalFeeIsPercentage ? this.moneyMaskToDecimal(this.withdrawalFeeValue || 0) : null,

                installments_maturity: this.installmentsMaturity,
                witnesses: JSON.stringify(this.witnesses),

                extension_session_absence_cutoff_day_for_meal_discount: this.extensionSessionEnable ? this.extensionSessionAbsenceCutoffDayForMealDiscount : null,
                extension_session_annuity: this.extensionSessionEnable ?  this.moneyMaskToDecimal(this.extensionSessionAnnuity) : null,
                extension_session_change_deadline: this.extensionSessionEnable ? this.extensionSessionChangeDeadline : null,
                extension_session_end: this.extensionSessionEnable ? this.extensionSessionEnd : null,
                extension_session_installment_value: this.extensionSessionEnable ? this.moneyMaskToDecimal(this.extensionSessionInstallmentValue) : null,
                extension_session_meals: this.extensionSessionEnable ? this.extensionSessionMeals : null,
                extension_session_min_absence_days_for_meal_discount: this.extensionSessionEnable ? this.extensionSessionMinAbsenceDaysForMealDiscount : null,
                extension_session_minimum_meal_days: this.extensionSessionEnable ? this.extensionSessionMinimumMealDays : null,
                extension_session_shift: this.extensionSessionEnable ? this.extensionSessionShift : null,
                extension_session_start: this.extensionSessionEnable ? this.extensionSessionStart : null,
                extension_session_weekdays: this.extensionSessionEnable ? this.extensionSessionWeekdays : null,
                contract_punctuality_discount_rule: this.punctualityDiscountRuleEqualToDueDate ? this.startDateInstallment : this.contractPunctualityDiscountRule
            });
        },
        hasDataFinalizationLabel() {
            return this.hasIncludedLabel("09_finalização_de_dados") || this.hasIncludedLabel("10_assinatura_de_contrato") || this.hasIncludedLabel("11_matrícula") || this.hasIncludedLabel("12_contrato_recusado") || this.hasIncludedLabel("13_matrícula_manual") || this.hasIncludedLabel("14_contrato_parcial");
        },
        hasIncludedLabel(label) {
            return this.selectedSchoolLabels.includes(label);
        },
        async fetchLabels() {
            if (!this.contact.id) {
                return;
            }
            this.$store.dispatch('contactLabels/get', this.contact.id);
        },
        async handleSubmitEnrollmentForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const payload = this.enrollmentPayload();
            try {
                await this.$store.dispatch('schoolEnrollments/update', { id: this.enrollment.id, ...payload });
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_CONTRACT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_CONTRACT.ERROR_MESSAGE'));
            }
        },

        checkEnrollment(selectedEnrollment) {
            const currentSchool = this.currentAccount?.school_accounts?.find(school_account => school_account?.school_document === selectedEnrollment?.enrollment_school_document);
            if(currentSchool?.enable_manual_contract) {
                this.contractTypes = ['Digital', 'Manual'];
            } else {
                this.contractType = 'Digital';
                this.chooseEnrollment(selectedEnrollment);
            }
        },

        chooseEnrollment(selectedEnrollment) {
            const { school_contract, enrollment_school_document } = selectedEnrollment;
            const school_account = this.currentAccount?.school_accounts?.find(school => school.school_document === enrollment_school_document);
            this.contractFiles = school_contract?.contract_files || [];

            this.contractorName = school_contract?.contractor_name || selectedEnrollment?.responsible_name || '';
            this.contractorAddress = school_contract?.contractor_address || 
                (selectedEnrollment?.responsible_street + ", " + selectedEnrollment?.responsible_building_number) || '';

            this.contractorDistrict = school_contract?.contractor_district || selectedEnrollment?.responsible_district || '';
            this.contractorState = this.getStateById(school_contract?.contractor_state || selectedEnrollment?.responsible_state) || '';
            this.contractorCity = school_contract?.contractor_city || selectedEnrollment?.responsible_city || '';
            this.contractorZipCode = school_contract?.contractor_zip_code || selectedEnrollment?.responsible_zip_code|| '';
            this.contractorPhoneNumber = school_contract?.contractor_phone_number || '';
            this.contractorEmail = school_contract?.contractor_email || '';
            this.contractorFirstDocument = school_contract?.contractor_first_document || selectedEnrollment?.responsible_first_document || '';
            this.contractorSecondDocument = school_contract?.contractor_second_document || '';
            this.contractorBirthDate = school_contract?.contractor_birth_date || '';
            this.contractorOccupation = school_contract?.contractor_occupation || '';
            this.contractorMaritalStatus = school_contract?.contractor_marital_status || '';

            this.studentSchoolClass = school_contract?.student_school_class || selectedEnrollment?.school_class || '';
            this.studentSchoolingType = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS').find(option => option.VALUE === school_contract?.student_schooling_type) || '';
            this.studentDateOfBirth = school_contract?.student_date_of_birth || '';
            this.studentName = school_contract?.contract_student_name || selectedEnrollment?.student_name || '';
            this.studentFirstDocument = school_contract?.contract_student_first_document || selectedEnrollment?.student_first_document || '';

            this.installmentMaturityOptions = school_account?.installments_maturity_options || [5,10,15,20]

            if (selectedEnrollment.school_year === '2024'){
                this.installmentsQuantityOptions = this.remainingMonths
            } else {
                this.installmentsQuantityOptions = school_account?.installments_quantity_options || [1,10,11,12,13]
            }

            this.schoolCity = school_contract?.school_city || school_account?.school_city || '';
            this.schoolEmail = school_contract?.school_email || school_account?.school_email || '';
            this.schoolDocument = school_contract?.school_document || school_account?.school_document || enrollment_school_document || '';
            this.schoolName = school_contract?.school_name || school_account?.school_name || '';
            this.schoolCorporateName = school_contract?.school_corporate_name || school_account?.school_corporate_name || '';
            this.schoolDistrict = school_contract?.school_district || school_account?.school_district || '';
            this.schoolPhoneNumber = school_contract?.school_phone_number || school_account?.school_phone_number || '';
            this.schoolAddress = school_contract?.school_address || school_account?.school_address || '';
            this.schoolZipCode = school_contract?.school_zip_code || school_account?.school_zip_code || '';
            this.schoolState = this.getStateById(school_contract?.school_state) || this.getStateById(school_account?.school_state) || '';

            this.annuity = school_contract?.annuity && parseFloat(school_contract.annuity).toFixed(2);
            this.installmentsMaturity = school_contract?.installments_maturity || '';

            this.punctualityDiscountPercentage = school_contract?.punctuality_discount_percentage || '';
            this.punctualityDiscountValue = school_contract?.punctuality_discount_value && parseFloat(school_contract?.punctuality_discount_value).toFixed(2);
            this.punctualityDiscountIsPercentage = !!this.punctualityDiscountPercentage; 

            this.contractPunctualityDiscountRule = school_contract?.contract_punctuality_discount_rule || '';
            this.punctualityDiscountRuleEqualToDueDate = (school_contract?.contract_punctuality_discount_rule === school_contract?.start_date_installment);

            this.withdrawalFeePercentage = school_contract?.withdrawal_fee_percentage || '';
            this.withdrawalFeeValue = school_contract?.withdrawal_fee_value && parseFloat(school_contract?.withdrawal_fee_value).toFixed(2);
            this.withdrawalFeeIsPercentage = !!this.withdrawalFeePercentage; 

            this.installmentsQuantity = school_contract?.installment_quantity || '';
            this.installmentValue = school_contract?.installment_value && parseFloat(school_contract?.installment_value).toFixed(2);

            this.startDateInstallment = school_contract?.start_date_installment || '';
            this.lastDayInstallment = school_contract?.last_day_installment || '';

            this.studentCustodyArrangement = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.OPTIONS').find(
                option => option.NAME === school_contract?.student_custody_arrangement
            ) || '';

            this.relationshipWithStudent = this.$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.OPTIONS')
                .find(option => option.NAME === school_contract?.relationship_with_student) || '';

            const maritalOption = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')
                .find(option => option.NAME === school_contract?.student_parents_marital_status) || '';

            if (maritalOption) {
                this.studentParentsMaritalStatus = maritalOption;
                this.studentParentsMaritalStatusOther = '';
            }
            else if (school_contract?.student_parents_marital_status) {
                this.studentParentsMaritalStatus = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')
                    .find(option => option.ID === "OTHER");
                this.studentParentsMaritalStatusOther = school_contract.student_parents_marital_status;
            } else {
                this.studentParentsMaritalStatus = '';
                this.studentParentsMaritalStatusOther = '';
            }
            this.witnesses = (school_contract?.witnesses && JSON.parse(school_contract?.witnesses)) || [];

            if(selectedEnrollment?.extension_session_enable && school_account?.extension_session_option_enable){
                this.extensionSessionEnable = true;
                this.extensionSessionAbsenceCutoffDayForMealDiscount = school_contract?.extension_session_absence_cutoff_day_for_meal_discount;
                this.extensionSessionAnnuity = school_contract?.extension_session_annuity && parseFloat(school_contract?.extension_session_annuity).toFixed(2);;
                this.extensionSessionChangeDeadline = school_contract?.extension_session_change_deadline;
                this.extensionSessionEnd = school_contract?.extension_session_end;
                this.extensionSessionInstallmentValue = school_contract?.extension_session_installment_value && parseFloat(school_contract?.extension_session_installment_value).toFixed(2);;
                this.extensionSessionMeals = school_contract?.extension_session_meals;
                this.extensionSessionMinAbsenceDaysForMealDiscount = school_contract?.extension_session_min_absence_days_for_meal_discount;
                this.extensionSessionMinimumMealDays = school_contract?.extension_session_minimum_meal_days;
                this.extensionSessionShift = school_contract?.extension_session_shift;
                this.extensionSessionStart = school_contract?.extension_session_start;
                this.extensionSessionWeekdays = school_contract?.extension_session_weekdays;
            }
        },
        addWitness() {
          this.witnesses.push({
            name: '',
            phone: '',
            email: '',
          });
        },
        removeWitness(index) {
          this.witnesses.splice(index, 1);
        },
        async handleAttachFile(event) {
            const [file] = event.target.files;

            const payload = {
                id: this.enrollment.id, 
                contractFile: file,
                contactId: this.contact.id, 
            }

            try {
                await this.$store.dispatch('schoolEnrollments/addContractFile', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.SUCCESS_MESSAGE'));
                this.contractFiles = this.currentEnrollment(this.enrollment.id).school_contract.contract_files;
            } catch (error) {
                if(error.cause.contract_file){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.INVALID_FILE_ERROR_MESSAGE'));
                } else if (error.cause.contract_files) {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.FILE_LIMIT_ERROR_MESSAGE'));
                } else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.ERROR_MESSAGE'));
                }
            }
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        async removeFile(contractFileId) {
            const payload = {
                id: this.enrollment.id, 
                contactId: this.contact.id,
                contract_file_id: contractFileId, 
            }
            try {
                await this.$store.dispatch('schoolEnrollments/removeContractFile', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.REMOVE_CONTRACT_FILE.SUCCESS_MESSAGE'));
                this.contractFiles = this.contractFiles.filter(contractFile => contractFile.id !== contractFileId);
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.REMOVE_CONTRACT_FILE.ERROR_MESSAGE'));
            }
        },
        validateMoneyGreaterThanZero(moneyValue) {
            if (this.moneyMaskToDecimal(moneyValue) <= 0) {
                return false 
            } 
            return true;
        },
        roundMoneyToFloor(value) {
            return (Math.floor(value * 100) / 100).toFixed(2);
        },
        async searchContractorZipCode() {
            try {
                const response = await this.fetchZipCode(this.contractorZipCode);
                const {data} = response;

                this.contractorState = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => option.ID === data.uf);
                this.contractorCity = data.localidade;
                this.contractorDistrict = data.bairro;
                this.contractorAddress = data.logradouro;
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_ZIPCODE.API_ERROR'));
            }
        },
        fixDateMaturityDay(value) {
            const date = new Date(value);

            const year = date.getUTCFullYear();
            const month = date.getUTCMonth();
            const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
            const day = Math.min(this.installmentsMaturity, lastDayOfMonth);

            return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        }
    }
}

</script>
<styles scoped lang="scss">

.contact--form {
    padding: var(--space-normal) var(--space-large) var(--space-large);
    min-height: 200px;

    .columns {
        padding: 0 var(--space-smaller);
    }
}

.add-button-area {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-bottom: 5px;
}

.add-button {
    height: 25px;
}

.enrollment-title {
    margin-bottom: 5px;
}

.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
  margin-bottom: var(--space-normal);
  background: var(--white);
  @apply dark:bg-slate-900 dark:border-slate-600
}

.filter-add {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  background: var(--w-25);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-top: var(--space-smaller);
  @apply dark:bg-slate-800 dark:border-slate-600
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-none {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-normal);
  font-weight: bold;
}

.filter-title {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.file-section-empty-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.file-section-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);
    padding: var(--space-small) ;
    background: var(--white);
    gap: var(--space-small);
    @apply dark:bg-slate-900 dark:border-slate-600
}

.file-section-item {
    background: var(--w-25);
    padding: var(--space-small);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);
    @apply dark:bg-slate-800 dark:border-slate-600
}

.file-section-upload-button {
    margin-top: var(--space-smaller);
}

</styles>